body {
  background-color: #1f2937;
  color: #fff;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 0 1 33%; 
  max-width: 50%;
  background-color: #273549;
  padding: 20px;  
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  text-align: center;
  margin-right: 20px;
  height: 200px;
}

.card2 {
  flex: 2; 
  max-width: 50%;
  background-color: #273549;
  padding: 20px;  
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  text-align: center;
  margin-right: 20px;
  height: 200px;
}

.card:last-child {
  margin-right: 0;
}

.empty-div{
  height: 100%;
}

button.actionbutton {
  background-color: #4f46e5;
  color: #fff;
  border: none;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #32325d;
}
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
button{
  background-color: #1f2937;
  color: #fff;
  width: 100%;
}